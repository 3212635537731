// Video

.Video{
   
    padding: 40px 0 40px;
    overflow: hidden;
    position: relative;
    &:before{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 10%;
        background: url(./img/bgVideo.png) no-repeat center right / contain;
        z-index: -1;
    }
    .imgVideo{
        text-align: center;
        .instagram-media{
            position: relative;
            box-shadow: 0px 4.48px 28.224px 0px #AEAEAE;
            z-index: 1;
            background: #FFF;
            border: 0;
            margin: 1px;
            max-width: 420px;
            min-width: 280px;
            width: 100%;
            margin: auto;
            display: inline-block;
            
        }
    }
    .textVideo{
        padding: 30px 10%;
        h3{
            color: #272727;
            font-size: 25px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            
            strong{
                font-weight: 900;
            }
        }
        p{
            color: #8F8A8A;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0.78px;
            margin-bottom: 10px;
            margin-left: 0;
        }
        h5{
            color: #ABABAB;
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            letter-spacing: 0.78px;
            text-transform: uppercase;
            margin-top: 40px;
        }
    }
}

@media (max-width: 768px) {
    .Video {
        background: #DEDEDE;
        padding: 60px 20px 60px;
        .textVideo {
            margin-bottom: 40px;
            padding: 60px 10px 0px;
        }
        .imgVideo{
            &::after {
                top: -18px;
                right: -18px;
            }
            &::before {
                top: 18px;
                left: -18px;
            }
        }
        .container{
            .row{
                .boxVideo {
                    order: 2;
                }
            }
        }
    }
}



@media(min-width: 1621px) and (max-width: 2000px) {
    .Video{
        &:before{
            left: 20%;
        }
    }
}

@media(min-width: 1024px) and (max-width: 1620px) {
    .Video{
        &:before{
            left: 40%;
        }
        iframe{
            width:260px !important;
            max-width: 260px !important;
            min-width: inherit;
        }
    }
}

@media(min-width: 1024px) and (max-width: 1280px) {
    .Video{
        &:before{
            left: 30%;
        }

    }
}
