// Modal

.modal-content{
    border: 1px solid #5F5F5F !important;
    .modal-header{
        display: block;
        text-align: center;
        background:  url(./img/modalEfeito1.png) no-repeat top left, #272727 url(./img/modalEfeito2.png) no-repeat bottom right;
        padding: 40px 0;
        i{
            margin-bottom: 20px;
            display: inline-block;
        }
        button.btn-close {
            position: absolute;
            right: 15px;
            top: 15px;
            background: url(./img/iconClose.png) center no-repeat;
            border: none;
        }
        .modal-title{
            color: #FFF;
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 900;
            line-height: 28px; 
            text-transform: uppercase;
        }
        p{
            color: #A7A7A7;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
    .modal-body{
        margin: 20px 5%;
        label{
            color: #272727;
            font-size: 11px;
            font-style: normal;
            font-weight: 800;
            line-height: 20px;
            text-transform: uppercase;
            width: 100%;
        }
        .form-control{
            border-radius: 0;
            background: none;
            border: none;
            margin-bottom: 10px;
            border-bottom: 1px solid  #F0F0F0;
            padding: 8px 0;
            &.danger {
                border-color: red;
            }
        }
        textarea{
            border-right: 1px solid  #F0F0F0 !important;
            resize: none;
        }
        .Label {
            width: 100%;
            text-transform: inherit;
            span{
                width: 100%;
                display: inline-block;
            }
        }
        .form-check {
            display: inline-block;
            margin-right: 20px;
        }
        .Dropdown{
            border-radius: 0;
            background: var(--whiteColor);
            border: none;
            box-shadow: none !important;
            width: 100%;
            height: 48px;
            line-height: 30px;
            padding-left: 0;
            border-bottom: 1px solid  #F0F0F0;
            .p-icon.p-dropdown-trigger-icon.p-clickable{
                color: #B1B7D1;
            }
            .p-dropdown-trigger{
                position: relative;
            }

        }
        .p-dropdown {
            span{
                color: var(--primaryColor);
                opacity: 0.6 !important;
                font-weight: 400 !important;
                padding-left: 0;
            }
        }
        .form-check-input {
            border: 4px solid #464850;
            width: 18px;
            height: 18px;
            background-color: #464850;
        }
        .form-check-input:checked {
            background-color: var(--tertiaryColor);
            background-image: none;
        }

        .form-check-label{
            font-size: 11px;
            text-transform: initial;
            letter-spacing: -0.1px;
            font-weight: 400;
        }
        Button{
            margin-top: 20px;
            float: right;
        }
    }
}
.p-dropdown-panel{
    z-index: 999999 !important;
}