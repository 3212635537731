
// nossosServicos

.nossosServicos{
    padding: 80px 0;
    position: relative; 
    overflow: hidden;
    .itemNossosServicos{
        text-decoration: none !important;
        .foto{
            width: 100%;
            height: 212px;
            display: inline-block;
            text-align: center;
            position: relative;
            transition: all ease-in 0.2s;
            width: 390px;
            &.fotoFunilaria{
                .rodaTraseira{
                    left: 59px;
                    top: 118px;
                    background: url(./img/imgFunalizariarRoda1.webp) no-repeat center / cover;
                    position: absolute;
                    width: 54px;
                    height: 54px;
                    z-index: 2;
                    transition: all ease-in 0.2s;
                }
                .rodaFrente{
                    right: 62px;
                    top: 118px;
                    background: url(./img/imgFunalizariarRoda2.webp) no-repeat center / cover;
                    position: absolute;
                    width: 54px;
                    height: 54px;
                    z-index: 2;
                    transition: all ease-in 0.2s;
                }
            }
            &.fotoBlindagem{
                .rodaTraseira{
                    left: 46px;
                    top: 117px;               
                    background: url(./img/imgBlindagemRoda1.webp) no-repeat center / cover;
                    position: absolute;
                    width: 54px;
                    height: 54px;
                    z-index: 2;
                    transition: all ease-in 0.2s;
                }
                .rodaFrente{
                    right: 49px;
                    top: 116px;
                    background: url(./img/imgBlindagemRoda2.webp) no-repeat center / cover;
                    position: absolute;
                    width: 54px;
                    height: 54px;
                    z-index: 2;
                    transition: all ease-in 0.2s;
                }
            }
            &.fotoEstetica{
                .rodaTraseira{
                    left: 52px;
                    top: 121px;
                    background: url(./img/imgEsteticaRoda1.webp) no-repeat center / cover;
                    position: absolute;
                    width: 54px;
                    height: 54px;
                    z-index: 2;
                    transition: all ease-in 0.2s;
                }
                .rodaFrente{
                    right: 44px;
                    top: 121px;
                    background: url(./img/imgEsteticaRoda2.webp) no-repeat center / cover;
                    position: absolute;
                    width: 54px;
                    height: 54px;
                    z-index: 2;
                    transition: all ease-in 0.2s;
                }
            }
            

            img{
                position: relative;
                z-index: 1;
                width: 100%;                
            }
            h3{
                text-transform: uppercase;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 60px;
                font-weight: 900;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: rgba(45, 25, 25, 0.366);
                color: transparent;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                transition: all ease-in 0.2s;
            }
        }
        .desc{
            background: #E1E1E1;
            border-radius: 4px;
            margin-top: -80px;
            display: inline-block;
            width: 100%;
            padding: 100px 30px 30px;
            position: relative;
            display: flex;
            justify-content: space-between;
            transition: all ease-in 0.2s;
            .servico{
                .textServico{
                    h6{
                        color: var(--secondaryTextColor);
                        text-transform: uppercase;
                        font-size: 12px;
                        text-decoration: none !important;
                    }
                    h5{
                        color: var(--primaryTextColor);
                        font-size: 16px;
                        text-transform: uppercase;
                        font-weight: bold;
                        height: 30px;
                    }
                }
            }
        }
        &:hover, &.active{
            cursor: pointer;
            .desc{
                background: var(--tertiaryColor);
                h6{
                    color: var(--primaryColor) !important;
                }
                h5{
                    color: var(--whiteColor) !important;
                }
            }
            .btn{
                background: var(--primaryColor);
                border-color: var(--primaryColor) !important;
                color: var(--whiteColor) !important;
                width: 80px;
            }
            .foto{
                margin-left: 30px;
                .rodaTraseira{
                    transform: rotate(20deg);
                }
                .rodaFrente{
                    transform: rotate(20deg);
                }
            }
            h3{
                margin-left: -50px !important;
            }
        }
        .btn{
            transition: all ease-in 0.2s;
        }
    }
    &::before{
        content: "Seviços";
        text-transform: uppercase;
        background: linear-gradient(90deg, rgba(217,217,217,0.60) 0%, rgba(217,217,217,0.3) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 76px;
        position: absolute;
        right: -80px;
        top: 242px;
        font-weight: 900;
        transform: rotate(270deg);
    }
}

@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .nossosServicos{
        padding: 80px 20px 80px 20px;
        &::before {
            display: none;
        }
        .itemNossosServicos {
            margin-top: 30px !important;
            .foto {
                width: 340px;
                img {
                    margin-top: 40px;
                }
                h3{
                    top: 33px;
                    font-size: 50px;
                }
                &.fotoFunilaria {
                    .rodaTraseira {
                        left: 53px;
                        top: 145px;
                        width: 44px;
                        height: 44px;
                    }
                    .rodaFrente {
                        right: 55px;
                        top: 145px;
                        width: 44px;
                        height: 44px;
                    }
                }
                &.fotoEstetica {
                    .rodaTraseira {
                        left: 46px;
                        top: 146px;
                        width: 46px;
                        height: 46px;
                    }
                    .rodaFrente {
                        right: 39px;
                        top: 147px;
                        width: 46px;
                        height: 46px;
                    }
                }
                &.fotoBlindagem {
                    .rodaTraseira {
                        left: 41px;
                        top: 144px;
                        width: 44px;
                        height: 44px;
                    }
                    .rodaFrente {
                        right: 44px;
                        top: 144px;
                        width: 44px;
                        height: 44px;
                    }
                }
            }
        }
    }
}
