// Experiência Personalizada

.experienciaPersonalizada{
    background: #EEEEEE url(img/bgExperienciaPersonalizada.webp) no-repeat center right;
    padding: 120px 0 80px;
    span{
        background: rgb(48,189,68);
        background: linear-gradient(90deg, rgba(48,189,68,0.4) 40%, rgba(238,238,238,0.5) 100%);
        border: 1px solid var(--tertiaryColor);
        color: #228234;
        font-weight: 600;
        border-radius: 4px;
        padding: 8px 20px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    h2{
        color: var(--primaryTextColor);
        font-size: 30px;
        margin: 20px 0;
        text-transform: uppercase;
        margin-bottom: 30px;
        display: inline-block;
        margin-top: 40px;
    }
    p{
        color: var(--secondaryTextColor);
        font-size: 14px;
        margin-bottom: 40px;
        width: 80%;
    }
    .img{
        position: relative;
        z-index: 2;
        .efeito{
            width: 700px;
            height: 350px;
            position: absolute;
            top: -30px;
            left: -80px;
            background: url(./img/EfeitoExperienciaPersonalizada.webp) no-repeat center / cover;
            z-index: -1;
        }
        img{
            width: 100%;
        }
    }

}

@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1380px) {
    .experienciaPersonalizada {
        background-position:  434px 0px !important;
        h2 {
            font-size: 26px;
        }
        span {
            padding: 6px 15px;
            font-size: 12px;
        }
    }
}
@media (max-width: 768px) {
    .experienciaPersonalizada {
        background: #EEEEEE url(img/bgExperienciaPersonalizada.webp) no-repeat center bottom;
        background-size: contain;
        text-align: center;
        padding: 100px 0 60px;
        span {

            padding: 6px 20px;
            font-size: 13px;
        }
        h2 {
            font-size: 22px;
            margin: 20px 0;
            margin-bottom: 20px;
            margin-top: 30px;
            line-height: 30px;
        }
        p {
            width: 100%;
            padding: 0 20px;
        }
        .img {
            position: relative;
            z-index: 2;
            width: 80%;
            margin: auto;
            margin-top: 60px;
            margin-top: 80px;
            .efeito{
                display: none;
            }
        }
        
    }
}
