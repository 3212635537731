// buscaBanner

.BannerOrcamento{
    background: #262626 url(./img/BannerOrcamento.webp) no-repeat center / cover;
    height: 600px;
    padding-top: 50px;
    .boxBannerOrcamento{
        min-height: 500px;
        display: table;
        position: relative;
        .textBannerOrcamento{
            display: table-cell;
            vertical-align: middle;
            min-height: 100vh;
            position: relative;
            h3{
                color: #2CBE48;
                font-size: 26px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                letter-spacing: 2.4px;
                text-transform: uppercase;
                line-height: 30px;
                margin-top: 40px;
            }
            h2{
                font-size: 36px;
                font-weight: 900;
                color: var(--whiteColor);
                text-transform: uppercase;
                line-height: 34px;
            }
            p{
                color: #F3F3F3;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 142.3%;
                letter-spacing: 0.96px; 
                width: 60%;
                text-transform: uppercase;
            }
            .btn{
                margin-top: 20px;
                margin-bottom: 0;
                &.btn-down{
                    margin-top: 20px;
                }
            }
        }
    }
    .imgBannerOrcamento{
        text-align: right;
        background-position: center;
        background-size:   90%;
        background-repeat: no-repeat;
        img{
         width: 90%;
         margin-top: 220px;
        }
     }

}

@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .BannerOrcamento {
        .boxBannerOrcamento {
            .textBannerOrcamento {
                padding: 20px;
                h3 {
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 0px;            
                }
                h2 {
                    font-size: 26px;
                    font-weight: 800;
                    line-height: 30px;
                }
                p {
                    color: #F3F3F3;
                    font-size: 15px;
                    line-height: 22px;
                    letter-spacing: 0.96px;
                    width: 80%;
                    text-transform: inherit;
                    margin-top: 10px;
                }
            }
        }
        .imgBannerOrcamento {
            position: absolute;
            top: 329px;
            right: 0;
            text-align: right;
            width: 70%;
            height: 168px;
            img{
                width: 240px;
                margin-top: 120px;
            }
        }
    }
}

