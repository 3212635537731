.loadingBoxDetails{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height:80vh;
}

.transition{
     transition: .1s;
    &-default{
       
        opacity: 1;
        pointer-events: auto;
    }
    &-none{ 
        opacity: 0;
        pointer-events: none;
    }
}

.especificacoesVeiculo {
    overflow: hidden;
    background: #262626 url(./img/efeitoEspecificacoes2.png) no-repeat left top;
    .color{
        min-height: 400px;
        display: table;
        padding: 120px 8% 120px 0;
        background: #262626 url(./img/efeitoEspecificacoes.png) no-repeat right bottom;
        h2{
            color: var(--tertiaryColor);
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 26px;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            border-bottom: 1px solid #45464A;
            padding: 10px 0;
            margin-bottom: 20px;
        }
        h4{
            color: #A7A7A7;
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            line-height: 20px;
            letter-spacing: 0.84px;
            text-transform: uppercase;
            border-bottom: 1px solid #45464A;
            padding-bottom: 6px;
        }
        h3{
            color: var(--whiteColor);
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            line-height: 18px;
            letter-spacing: 0.84px;
            text-transform: uppercase;
            margin-top: 20px;
        }
        h5{
            color: #A7A7A7;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.84px;
        }
        p{
            color: #A7A7A7;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            padding-bottom: 6px;
        }
        .boxColor{
            display: table-cell;
            vertical-align: middle;
            min-height: 400px;
        }
    }
    .white{
        padding: 120px 10% 120px 6%;
        background: var(--whiteColor);
        position: relative;
        min-height: 400px;
        display: table;
        &::before{
            content: "";
            width: 100%;
            top: 0;
            left: 100%;
            height: 100%;
            background:  var(--whiteColor);
            position: absolute;
        }
        h6{
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: var(--secondaryColor);
            font-weight: 900;
            text-transform: uppercase;
            font-size: 38px;
            color: transparent;
            line-height: 40px;
        }
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            li{
                border-bottom: 1px solid #F0F0F0;
                color: #35363A;
                font-size: 16px;
                font-style: normal;
                font-weight: 800;
                line-height: 22px;
                letter-spacing: 0.96px;
                padding-bottom: 10px;
                span{
                    color: #A7A7A7;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 20px;
                    letter-spacing: 0.72px;
                    display: inline-block;
                    width: 100%;
                }
                &:last-child{
                    border: none;
                }
            }
        }
        .boxWhite{
            display: table-cell;
            vertical-align: middle;
            min-height: 400px;
        }
    }
}

.fichatecnicaVeiculo{
    padding: 80px 0;
    position: relative;
    background: var(--whiteColor);
    h3{
        color: #ABABAB;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    h4{
        color: #35363A;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: 0.84px;
        text-transform: uppercase;  
        margin: 0;
        margin-bottom: 20px;
    }
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            margin: 0;
            margin-left: -5px;
            padding: 8px 30px;
            list-style: none;
            position: relative;
            color: #ABABAB;
            &::before{
                content: "";
                width: 5px;
                height: 5px;
                background: #ABABAB;
                border-radius: 10px;
                position: absolute;
                top: 16px;
                left: 14px;
            }
            &:nth-child(odd){
                background: #F4F4F4;
            }
            &:nth-child(even){
                background: none;
            }
        }
        
    }
}

@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .fichatecnicaVeiculo {
        overflow: hidden;
       padding: 60px 20px;
       h4 {
            margin-bottom: 20px;
            margin-top: 20px;
        }
    }
}

