// Garantimos

.Garantimos{
    padding: 60px 0;
    margin-top: 40px;
    overflow: hidden;
    .boxGarantimos{
        background: url(./img/bgGarantimos.webp) no-repeat center / cover;
        width: 100%;
        height: 400px;
        display: inline-block;
        margin: auto;
        border-radius: 8px;
        padding: 90px 0;
        position: relative;
        text-align: right;
        h3{
            font-size: 18px;
            color: var(--whiteColor);
            text-transform: uppercase;
            font-weight: bold;
        }
        h2{
            font-size: 30px;
            color: var(--whiteColor);
            text-transform: uppercase;
            font-weight: bold;
        }
        p{
            color: #FFF;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.78px; 
        }
        .btn{
            width: 80%;
            margin-top: 20px;
        }
    }
}


@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .Garantimos{
        padding: 40px 0 80px;
        margin-top: 40px;
        overflow: hidden;
        .boxGarantimos{
            padding: 80px 30px 30px 30px;
            text-align: center;
            height: 500px;
            background: url(./img/bgGarantimosMobile.webp) no-repeat center / cover;
            h2 {
                font-size: 21px;
                color: var(--whiteColor);
                text-transform: uppercase;
                font-weight: 500;
            }
            h3{
                color: #ABABAB;
                font-size: 12px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 0.72px;
                text-transform: uppercase;
            }
            .btn {
                width: 72%;
                margin-top: 9px;
                padding: 10px 20px !important;
            }
        }
    }
}