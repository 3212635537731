// GaleriaCeramicPro

.GaleriaCeramicPro{
    padding: 120px 0;
    height: auto;
    &.bg{
    }
    h2{
        color: var(--whiteColor);
        text-align: left;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.8px;
        text-transform: uppercase;  
        margin-bottom: 40px;
    }
    h4{
        color: var(--whiteColor);
        font-size: 13px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.78px;
        text-transform: uppercase;
        text-align: left;
    }
    p{
        color: var(--whiteColor);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.78px;
    }
    .slick-arrow{
        display: none !important;
    }
    .slick-dots {
        bottom: -60px;
        li.slick-active button:before {
            font-size: 15px;
            color: var(--tertiaryColor);
        }
        li button:before{
            color: #696868;
        }
     }
    .controlSlider{
        position: relative;
        a.nextSlider {
            border: 1px solid #D0D0D0;
            width: 52px;
            height: 52px;
            z-index: 1;
            background: #484747;
            position: absolute;
            right: 21px;
            bottom: -60px;
            color: #D0D0D0;
            text-align: center;
            line-height: 48px;
            transition: all ease-in 0.2s;
            font-size: 12px;
            &:hover{
                background: #F5F5F5;
                cursor: pointer;
            }
        }
        a.prevSlider {
            border: 1px solid #D0D0D0;
            width: 52px;
            height: 52px;
            z-index: 1;
            background: #484747;
            position: absolute;
            right: 72px;
            bottom: -60px;
            color: #D0D0D0;
            text-align: center;
            line-height: 48px;
            transition: all ease-in 0.2s;
            font-size: 12px;
            &:hover{
                background: #F5F5F5;
                cursor: pointer;
            }
        }
    }
    .itemGaleriaCeramicPro{
        padding: 10px;
        .imgGaleriaCeramicPro{
            height: 240px;
            width: 100%;     
            img{
                width: 100%;
            }      
        }
    }
}

@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .GaleriaCeramicPro {
        padding: 60px 0 120px 0;
        height: auto;
        background: #2D2D2D;
        position: relative;
        top: -1px;
    }
}

