//View
.View{
    margin-bottom: 40px;
    .boxView{
        .header{
            background: #35363A;
            padding: 20px 40px;
            text-align: center;
            margin-top: -80px;
            h5{
                color: #A1A1A1;
                font-size: 18px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 1.44px;
                line-height: 30px;
                text-transform: uppercase;
                margin: 0;
            }
            h4{
                color: #FFF;
                text-align: center;
                line-height: 32px;
                font-size: 22px;
                font-style: normal;
                font-weight: 800;
                letter-spacing: 1.74px;
                text-transform: uppercase;
                margin: 0;
            }
            h3{
                color: #2CBE48;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 1.02px;
                list-style: 20px;
                text-transform: uppercase;
            }
        }
    }
    .boxResumo{
        border: 1px solid #E4E3E3;
        background: #FFF;
        padding: 30px 30px;
        margin: 10px 0 0;
        position: relative;
        h4{
            color: #A7A7A7;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
        h3{
            color: #FFF;
            font-size: 11px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            background: #35363A;
            padding: 6px 20px 6px 40px;
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0 0 0 14px;
            i{
                color: #2CBE48;
                font-size: 18px;
                top: 1px;
                left: 16px;
                position: absolute;
            }
        }
        ul{
            list-style: none;
            border-bottom: 8px solid #FFF;
            padding: 0;
            margin-bottom: 0;
            display: inline-block;
            width: 100%;
            background: #F4F4F4;
            li{
                background: none;
                padding: 0;
            }
            ul{
                li{
                   
                    width: 100%;
                    padding: 8px 20px;
                    color: #35363A;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 0.20px;
                    text-transform: uppercase;
                    display: inline-block;
                    margin-bottom: 0;
                    width: calc(100% - 140px);
                    span{
                        display: inline-block;
                    }
                    &:nth-child(2){
                        text-align: right;
                        color: #A7A7A7;
                        width: 140px;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.20px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    .boxValor{
        border: 1px solid #E4E3E3;
        background: #E0E0E0;
        padding: 20px 30px;
        P{
            color: #272727;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.32px;
            width: calc(100% - 230px);
            display: inline-block; 
        }
        h2{
            color: #272727;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.32px;
            text-transform: uppercase;
            width: calc(100% - 230px);
            display: inline-block;
            span{
                color: #828282;
                font-size: 11.2px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.20px;
                text-transform: uppercase;
                margin-bottom: 5px;
                width: 100%;
                display: block;
            }
        }


        a{
            float: right;
            padding-left: 30px !important;
            padding-right: 30px !important;
            padding: 14px 20px !important;
        }
    }
}

@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .View {
        .boxValor {
            display: flow-root;
            margin-bottom: 40px;
            h2 {
                width: 100%;
                text-align: center;
            }
            button {
                float: inherit;
                width: 100%;
                margin-top: 15px;
            }
            p{
                width: 100%;
            }
            a{
                width: 100%;
            }
        }
        .boxResumo {
            padding: 30px 20px;
            h4 {
                margin-top: 20px;
                font-size: 14px;
            }
            ul {
                ul {
                    li{
                        &:nth-child(2) {
                            width: 126px;
                        }
                        padding: 8px 11px;
                        line-height: 17px;
                        font-size: 11px;
                        width: calc(100% - 126px);
                    }
                }
            }
        }
    }
}